import { useEffect, useRef, useState } from 'react';
import routes from 'routes';
import { batch } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { getIsSuper, getIsDev, has_permission } from 'config/permissions';
import { dimensions } from 'constants/dimensions';
import { chartInitialValues } from 'constants/chart';
import Footer from 'components/Footer';
import Loader from 'components/Loader';
import Sidebar from 'components/Sidebar/Sidebar';
import { SmallCard } from 'components/SmallCard';
import { CustomHeader } from 'components/CustomHeader';
import RegularTable from 'components/RegularTable';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { urlDate, chartDate } from 'helpers/date';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { numberWithDot, percentDiff, sumAmounts } from 'helpers/utils';
import { getUserState } from 'selectors/user';
import { getDonorData } from 'selectors/donors';
import { getSummaryData } from 'selectors/summary';
import { getTotalTransactionData } from 'selectors/transactions';
import { getTotalDonationData, getTrendDonationData } from 'selectors/donations';
import { getAccessToken } from 'services/storage';
import { getTableDonors } from 'store/donor/actions';
import { getTableSummary } from 'store/summary/actions';
import { getTransactionTotal } from 'store/transaction/actions';
import { getDonationTotal, getDonationTrend } from 'store/donation/actions';
import { IDonorTotal } from 'store/donor/types';
import { ISummaryTotals } from 'store/summary/types';

const Dashboard = props => {
  const mainPanel = useRef<any>();
  const dispatch = useAppDispatch();
  const [donationDiff, setDonationDiff] = useState('-');
  const [transactionDiff, setTransactionDiff] = useState('-');
  const [chartData, setChartData] = useState(chartInitialValues);
  const [dimensionID, setDimensionID] = useState({ value: 1, label: 'recipients' });

  const Today = new Date();
  const Yesterday = new Date().setDate(new Date().getDate() - 1);

  const {
    userState,
    totalDonation,
    totalTransaction,
    donationTrend,
    summary,
    donors,
    pageLoaderLoading,
  } = useAppSelector(state => ({
    userState: getUserState(state),
    totalDonation: getTotalDonationData(state),
    totalTransaction: getTotalTransactionData(state),
    donationTrend: getTrendDonationData(state),
    summary: getSummaryData(state),
    donors: getDonorData(state),
    pageLoaderLoading:
      state.donation.isLoading ||
      state.transaction.isLoading ||
      state.summary.isLoading ||
      state.donor.isLoading,
  }));

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  useEffect(() => {
    if (
      userState?.clientID &&
      !isNaN(userState?.dates?.start) &&
      !userState?.datesUpdated &&
      getAccessToken()
    ) {
      batch(() => {
        dispatch(
          getDonationTotal(
            'today',
            urlDate(Today, 'start'),
            urlDate(Today, 'end'),
            userState.clientID
          )
        );
        dispatch(
          getDonationTotal(
            'yesterday',
            urlDate(Yesterday, 'start'),
            urlDate(Yesterday, 'end'),
            userState.clientID
          )
        );
        dispatch(
          getTransactionTotal(
            'today',
            urlDate(Today, 'start'),
            urlDate(Today, 'end'),
            userState.clientID
          )
        );
        dispatch(
          getTransactionTotal(
            'yesterday',
            urlDate(Yesterday, 'start'),
            urlDate(Yesterday, 'end'),
            userState.clientID
          )
        );
        dispatch(
          getDonationTrend(
            urlDate(userState.dates.start, 'start'),
            urlDate(userState.dates.end, 'end'),
            userState.clientID
          )
        );
        dispatch(
          getTableSummary(
            urlDate(userState.dates.start, 'start'),
            urlDate(userState.dates.end, 'end'),
            dimensions.find(item => item.value === dimensionID.value)?.label || 'recipient',
            userState.clientID
          )
        );
        dispatch(
          getTableDonors(
            urlDate(userState.dates.start, 'start'),
            urlDate(userState.dates.end, 'end'),
            userState.clientID
          )
        );
      });
    }
  }, [userState]);

  useEffect(() => {
    if (userState?.clientID && !isNaN(userState?.dates?.start)) {
      dispatch(
        getTableSummary(
          urlDate(userState.dates.start, 'start'),
          urlDate(userState.dates.end, 'end'),
          dimensions.find(item => item.value === dimensionID.value)?.label || 'recipient',
          userState.clientID
        )
      );
    }
  }, [dimensionID]);

  useEffect(() => {
    if (userState?.clientID && userState?.datesUpdated && !isNaN(userState?.dates?.start)) {
      batch(() => {
        dispatch(
          getDonationTrend(
            urlDate(userState.dates.start, 'start'),
            urlDate(userState.dates.end, 'end'),
            userState.clientID
          )
        );
        dispatch(
          getTableSummary(
            urlDate(userState.dates.start, 'start'),
            urlDate(userState.dates.end, 'end'),
            dimensions.find(item => item.value === dimensionID.value)?.label || 'recipient',
            userState.clientID
          )
        );
        dispatch(
          getTableDonors(
            urlDate(userState.dates.start, 'start'),
            urlDate(userState.dates.end, 'end'),
            userState.clientID
          )
        );
      });
    }
  }, [userState?.dates]);

  useEffect(() => {
    if (totalDonation?.today !== undefined && totalDonation?.yesterday !== undefined) {
      setDonationDiff(percentDiff(totalDonation.today, totalDonation.yesterday));
    }
  }, [totalDonation]);

  useEffect(() => {
    if (totalTransaction?.today !== undefined && totalTransaction?.yesterday !== undefined) {
      setTransactionDiff(percentDiff(totalTransaction.today, totalTransaction.yesterday));
    }
  }, [totalTransaction]);

  useEffect(() => {
    if (donationTrend?.length > 0) {
      const newData = chartData;
      newData.data.labels = donationTrend.map(item => chartDate(item.date));
      newData.data.datasets[0].data = donationTrend.map(item => parseInt(item.amount));
      setChartData(newData);
    }
  }, [donationTrend]);

  return (
    <>
      {pageLoaderLoading && <Loader type="dots" />}
      <div className="wrapper">
        <Sidebar {...props} routes={routes} bgColor="black" activeColor="info" />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar {...location} handleMiniClick={handleMiniClick} />
          {!has_permission('SCREEN_VIEW_PERMISSION', 'R') ? (
            <div className="content">
              <h1 className="title-permission">
                Sorry, you don't have permission to see this screen. Switch the environment to
                staging.
              </h1>
            </div>
          ) : (
            <div className="content">
              <Row>
                <Col md={getIsSuper() || getIsDev() ? '4' : '6'} sm="12">
                  <h1 className="title-dashboard">Impact Dashboard</h1>
                </Col>
                <CustomHeader withDates />
              </Row>
              <Row>
                <Col lg="3" md="3" sm="12">
                  <Row style={{ height: '50%' }}>
                    <SmallCard
                      title="Raised Today"
                      subtitle="Yesterday"
                      titleValue={`$${numberWithDot(totalDonation?.today)}`}
                      subtitleValue={`$${numberWithDot(totalDonation?.yesterday)}`}
                      badgeStyle={`${parseInt(donationDiff) > 0 ? 'blue' : 'orange'}-badge`}
                      badgeValue={`${donationDiff} %`}
                      icon={<i className="nc-icon nc-money-coins text-success" />}
                    />
                  </Row>
                  <Row style={{ height: '50%' }}>
                    <SmallCard
                      title="Transactions Today"
                      subtitle="Yesterday"
                      titleValue={totalTransaction?.today?.toString() || '...'}
                      subtitleValue={totalTransaction?.yesterday?.toString() || '...'}
                      badgeStyle={`${parseInt(transactionDiff) > 0 ? 'blue' : 'orange'}-badge`}
                      badgeValue={`${transactionDiff} %`}
                      icon={<i className="fa fa-exchange text-purple" />}
                    />
                  </Row>
                </Col>
                <Col lg="9" md="9" sm="12">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col sm="12">
                          <div className="numbers pull-left">
                            ${' '}
                            {numberWithDot(
                              sumAmounts(
                                donationTrend?.map(item => parseFloat(item.amount))
                              )?.toFixed(2)
                            )}
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <h5 className="big-title">Recent Giving Activity</h5>
                      {chartData.data.datasets[0].data.length > 0 && (
                        <Line
                          data={chartData.data}
                          options={chartData.options}
                          height={380}
                          width={826}
                          redraw={true}
                        />
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="4" md="4" sm="12">
                  <RegularTable<ISummaryTotals>
                    type="summary"
                    title="Summary"
                    subtitle={`By ${dimensions.find(item => item.value === dimensionID.value)?.label || 'recipient'}`}
                    columns={[
                      dimensions.find(item => item.value === dimensionID.value)?.label ||
                        'recipient',
                      'Amount Donated',
                      '# of Donors',
                    ]}
                    data={summary?.filter(
                      item =>
                        !item?.recipient?.includes('Oath') &&
                        !item?.recipient?.includes('51C Collaborative')
                    )}
                    dropdownValue={dimensionID}
                    dropdownOnChange={setDimensionID}
                    dropdownOptions={dimensions}
                    dropdownPlaceholder="Select Dimension"
                    withPagination
                  />
                </Col>
                <Col lg="8" md="8" sm="12">
                  <RegularTable<IDonorTotal>
                    type="donors"
                    title="Most Recent Donations"
                    subtitle="Listed in order of donation date"
                    columns={['Donation Date', 'Donor Name', 'Amount Donated', 'Reference Code']}
                    data={donors?.slice(0, 5)}
                  />
                </Col>
              </Row>
            </div>
          )}
          <Footer fluid />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
