import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const summaryStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const summarySuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const summaryError = (state, { error }) => ({
  ...state,
  error: {
    summaryError: error,
  },
  isLoading: false,
});

export const summaryCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.SUMMARY_START]: summaryStart,
  [Types.SUMMARY_SUCCESS]: summarySuccess,
  [Types.SUMMARY_ERROR]: summaryError,
  [Types.SUMMARY_CLEAN_UP]: summaryCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
