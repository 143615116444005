import { ReactElement, useEffect, useRef, useState } from 'react';
import routes from 'routes';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import { has_permission, getIsSuper, getIsDev } from 'config/permissions';
import { pageColumns } from 'constants/tables';
import Footer from 'components/Footer';
import Loader from 'components/Loader';
import Sidebar from 'components/Sidebar/Sidebar';
import { CustomHeader } from 'components/CustomHeader';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { CustomTable, tableButton } from 'components/CustomTable';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getUserState } from 'selectors/user';
import { getPagesState } from 'selectors/pages';
import { getClientID, getClientName, getAccessToken } from 'services/storage';
import { getAllPages, getPages, updatePage } from 'store/pages/actions';
import { ENV_CONFIG } from 'config/environment';

interface IData {
  id: number;
  pageName: string;
  webUrl: string;
  amounts: string;
  actions: ReactElement;
}
const Pages = props => {
  const mainPanel = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [dataState, setDataState] = useState(Array<IData>());

  const { pagesState, userState } = useAppSelector(state => ({
    pagesState: getPagesState(state),
    userState: getUserState(state),
  }));

  useEffect(() => {
    if (getClientID() && getAccessToken()) {
      dispatch(getPages(getClientID() || ''));
      if (!pagesState.allPages) dispatch(getAllPages());
    }
  }, [userState]);

  useEffect(() => {
    if (!pagesState.isLoading && pagesState.data?.pages) {
      if (pagesState.data?.pageCreated) dispatch(getPages(getClientID() || ''));
      setDataState(
        pagesState.data.pages
          .filter(page => page.tag.is_enabled)
          ?.map((prop, key) => {
            return {
              id: key,
              pageName: prop.tag.value,
              webUrl: prop.tag.query_str,
              amounts: `[${prop.tag.donation_amounts.toString()}]`,
              actions: (
                <div className="actions-right">
                  {tableButton(handleUpdate, 'redirect', prop.tag.query_str)}
                  {has_permission('PAGES_ROUTE', 'U') &&
                    tableButton(handleUpdate, 'duplicate', prop.tag.id)}
                  {has_permission('PAGES_ROUTE', 'U') &&
                    tableButton(handleUpdate, 'edit', prop.tag.id)}
                  {has_permission('PAGES_ROUTE', 'D') &&
                    tableButton(handleUpdate, 'disable', prop.tag.id)}
                </div>
              ),
            };
          })
      );
    }
  }, [pagesState]);

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const handleCreate = () => {
    navigate('/pages_detail');
  };

  const handleUpdate = (type: string, param: string | number) => {
    if (type === 'redirect') window.open(`${ENV_CONFIG().PARTNER_PAGE}${param}`, '_blank');
    if (type === 'edit') navigate(`/pages_detail?tag_id=${param}`);
    if (type === 'duplicate') navigate(`/pages_detail?tag_id=${param}&copy`);
    if (type === 'disable') {
      const body = pagesState?.data?.pages?.find(page => page.tag.id === param);
      if (body) {
        const updateBody = {
          client_id: getClientID() || '',
          page: {
            ...body,
            tag: {
              ...body.tag,
              is_enabled: false,
            },
          },
        };
        dispatch(updatePage(updateBody));
      }
    }
  };

  return (
    <>
      {pagesState.isLoading && <Loader type="dots" />}
      <div className="wrapper">
        <Sidebar {...props} routes={routes} bgColor="black" activeColor="info" />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar {...location} handleMiniClick={handleMiniClick} />
          {!has_permission('SCREEN_VIEW_PERMISSION', 'R') ? (
            <div className="content">
              <h1 className="title-permission">
                Sorry, you don't have permission to see this screen. Switch the environment to
                staging.
              </h1>
            </div>
          ) : (
            <div className="content">
              <Row>
                <Col md={getIsSuper() || getIsDev() ? '8' : '10'} sm="12">
                  <h1 className="title-dashboard">{getClientName()} Pages</h1>
                </Col>
                <CustomHeader />
                {has_permission('PAGES_ROUTE', 'C') && (
                  <Col md="2" style={{ alignSelf: 'flex-end' }}>
                    <Button block className="btn-round mb-3" color="success" onClick={handleCreate}>
                      Create New
                    </Button>
                  </Col>
                )}
              </Row>
              <Row>
                <Col lg="12">
                  <CustomTable
                    subtitle="A full list of your pages can be found below. Reach out to partners@oath.vote if you need any support creating new or editing existing pages."
                    data={dataState}
                    columns={pageColumns}
                    withPagination
                  />
                </Col>
              </Row>
            </div>
          )}
          <Footer fluid />
        </div>
      </div>
    </>
  );
};

export default Pages;
