import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Nav, Collapse } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { ENV_CONFIG } from 'config/environment';
import { has_permission } from 'config/permissions';
import { limitChars } from 'helpers/utils';
import { useAppDispatch } from 'helpers/hooks';
import {
  setAccessToken,
  setRoleType,
  getRoleType,
  getClientName,
  setClientName,
  setClientNames,
  getClientIDs,
  setClientIDs,
  getClientID,
  setClientID,
  setSessionStartDate,
  setSessionEndDate,
  getSessionStartDate,
  getSessionEndDate,
  clearLocalStorage,
} from 'services/storage';
import { setUserClientID, setUserClients, setUserDates } from 'store/user/actions';

const Sidebar = props => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openAvatar, setOpenAvatar] = useState(false);
  const [auth0Response, setAuth0Response] = useState<any>();
  const [collapseStates, setCollapseStates] = useState({});
  const { user, isLoading, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) navigate('/');
      if (auth0Response && !auth0Response.error) {
        if (!auth0Response.app_metadata?.clients?.length) {
          handleLogout();
          console.warn('Metadata clients is missing');
        } else {
          if (auth0Response.app_metadata.role_type === 'super_admin') {
            if (!getRoleType()) {
              //TIP: Set initial dropdown value
              if (window.location.origin === 'https://admin.oath.vote')
                setRoleType(process.env.REACT_APP_SUPER_ADMIN_PRODUCTION || '');
              else setRoleType(process.env.REACT_APP_SUPER_ADMIN_STAGING || '');
            }
          } else if (auth0Response.app_metadata.role_type === 'dev_admin') {
            if (!getRoleType()) setRoleType(process.env.REACT_APP_DEV_ADMIN_PRODUCTION || '');
          } else {
            setRoleType(process.env.REACT_APP_PARTNER_ADMIN_PRODUCTION || '');
          }
          setSessionStartDate(new Date().setDate(new Date().getDate() - 13).toString());
          setSessionEndDate(new Date().getTime().toString());
          dispatch(
            setUserDates(
              parseInt(getSessionStartDate() || ''),
              parseInt(getSessionEndDate() || ''),
              false
            )
          );
          if (!getClientIDs()) {
            setClientIDs(auth0Response.app_metadata.clients.map(client => client.id));
            setClientNames(auth0Response.app_metadata.clients.map(client => client.name));
          }
          if (!getClientID()) {
            setClientID(auth0Response.app_metadata.clients[0].id);
            setClientName(auth0Response.app_metadata.clients[0].name);
            dispatch(setUserClientID(auth0Response.app_metadata.clients[0].id));
            dispatch(setUserClients(auth0Response.app_metadata.clients));
          }
        }
      }
    }
  }, [auth0Response, isLoading]);

  const handleLogout = () => {
    clearLocalStorage();
    logout({ logoutParams: { returnTo: ENV_CONFIG().SITE_URL } });
  };

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${ENV_CONFIG().AUTH.DOMAIN}/api/v2/`,
            scope: 'read:current_user',
          },
        });

        setAccessToken(accessToken);
        const userDetailsByIdUrl = `https://${ENV_CONFIG().AUTH.DOMAIN}/api/v2/users/${user?.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const res = await metadataResponse.json();
        setAuth0Response(res);
      } catch (e: any) {
        console.warn(e.message);
      }
    };

    if (!auth0Response && user?.sub) getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  const sidebar = useRef<any>();
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = routes => {
    let initialState = {};
    routes.map(prop => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.children),
          ...getCollapseStates(prop.children),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/children/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = routes => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].children)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.redirect || !has_permission(prop.permission, 'R')) {
        return null;
      }
      if (prop.collapse) {
        const st = {};
        st[prop['state']] = !collapseStates[prop.state];
        return (
          <li className={getCollapseInitialState(prop.children) ? 'active' : ''} key={key}>
            <a
              href="#"
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={e => {
                e.preventDefault();
                setCollapseStates(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseStates[prop.state]}>
              <ul className="nav">{createLinks(prop.children)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={activeRoute(prop.path)} key={key}>
          <Link to={prop.path}>
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </Link>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => {
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes));
  }, []);

  return (
    <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
      <div className="logo">
        <div className="logo-img">
          <img src={require('../../assets/img/simple_logo.png')} className="logo-img-file" />
        </div>
      </div>

      <div className="sidebar-wrapper" ref={sidebar}>
        <div className="user">
          <div className="photo">
            {auth0Response?.picture && <img src={auth0Response.picture} alt="Avatar" />}
          </div>
          <div className="info">
            <a
              href="#"
              data-toggle="collapse"
              aria-expanded={openAvatar}
              onClick={() => setOpenAvatar(!openAvatar)}
            >
              <span>
                {limitChars(getClientName() || auth0Response?.name, 15) || 'Oath User'}
                {has_permission('USER_PROFILE_ROUTE', 'R') && <b className="caret" />}
              </span>
            </a>
            {has_permission('USER_PROFILE_ROUTE', 'R') && (
              <Collapse isOpen={openAvatar}>
                <ul className="nav">
                  <li>
                    <Link to="/user-profile">
                      <span className="sidebar-mini-icon">P</span>
                      <span className="sidebar-normal">Profile</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-profile">
                      <span className="sidebar-mini-icon">S</span>
                      <span className="sidebar-normal">Settings</span>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            )}
          </div>
        </div>
        <Nav>{createLinks(props.routes)}</Nav>
      </div>
    </div>
  );
};

export default Sidebar;
