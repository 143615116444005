export const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
  clientID: null,
  clients: null,
  dates: {
    start: null,
    end: null,
  },
  datesUpdated: false,
};
