import { useEffect, useState } from 'react';
import Select from 'react-select';
import ReactDatetime from 'react-datetime';
import { CardTitle, Col, FormGroup } from 'reactstrap';
import { getIsSuper, getIsDev } from 'config/permissions';
import { inputDate } from 'helpers/date';
import { useAppSelector, useAppDispatch } from 'helpers/hooks';
import { getUserState } from 'selectors/user';
import {
  getClientID,
  getClientIDs,
  setClientID,
  getSessionStartDate,
  getSessionEndDate,
  setSessionStartDate,
  setSessionEndDate,
  setClientName,
  getClientNames,
  getClientName,
} from 'services/storage';
import { setUserClientID, setUserDates } from 'store/user/actions';

interface IClientID {
  value: string;
  label: string;
}

export const CustomHeader = ({ withDates }: { withDates?: boolean }) => {
  const dispatch = useAppDispatch();
  const [clients, setClients] = useState(Array<IClientID>());
  const [clientIDSelected, setClientIDSelected] = useState<IClientID>();
  const [startDate, setStartDate] = useState<number>();
  const [endDate, setEndDate] = useState<number>();
  const userState = useAppSelector(getUserState);

  useEffect(() => {
    let clientIDs = Array<string>();
    let clientNames = Array<string>();
    const arr = Array<IClientID>();
    if (userState.clients?.length) {
      clientIDs = userState.clients.map(client => client.id);
      clientNames = userState.clients.map(client => client.name);
    } else if (getClientID() && getClientIDs()) {
      clientIDs = getClientIDs()?.split(',') || [];
      clientNames = getClientNames()?.split(',') || [];
    }

    if (!clients?.length && clientIDs.length && clientNames.length) {
      for (let i = 0; i < clientIDs.length; i++) {
        arr.push({ value: clientIDs[i], label: clientNames[i] });
      }
      if (getIsSuper()) arr.unshift({ value: 'None', label: 'ALL' });
      setClients(arr);
      setClientIDSelected({
        label: getClientName() || arr[0].label,
        value: getClientID() || arr[0].value,
      });
      dispatch(setUserClientID(getClientID() || ''));
    }
  }, [userState.clients]);

  useEffect(() => {
    if (!userState.dates.start) {
      dispatch(
        setUserDates(
          parseInt(getSessionStartDate() || ''),
          parseInt(getSessionEndDate() || ''),
          false
        )
      );
    }
  }, []);

  useEffect(() => {
    if (getSessionStartDate() && getSessionEndDate()) {
      setStartDate(parseInt(getSessionStartDate() || ''));
      setEndDate(parseInt(getSessionEndDate() || ''));
    }
  }, [getSessionStartDate()]);

  const handleChangeClient = item => {
    dispatch(setUserClientID(item.value)); //ReduxState
    setClientIDSelected(item); //UseState
    setClientID(item.value); //LocalStorage
    setClientName(item.label); //LocalStorage
  };

  const handleChangeDates = (type: string, item) => {
    if (type === 'start') {
      setStartDate(new Date(item).getTime());
      dispatch(setUserDates(new Date(item).getTime(), new Date(endDate || '').getTime(), true));
      setSessionStartDate(new Date(item).getTime().toString());
    } else {
      setEndDate(new Date(item).getTime());
      dispatch(setUserDates(new Date(startDate || '').getTime(), new Date(item).getTime(), true));
      setSessionEndDate(new Date(item).getTime().toString());
    }
  };

  return (
    <>
      {(getIsSuper() || getIsDev()) && (
        <Col md="2" sm="12" xs="12">
          <CardTitle>Client</CardTitle>
          <FormGroup>
            <Select
              className="react-select primary react-select-dropdown"
              classNamePrefix="react-select"
              name="singleSelect"
              value={clientIDSelected}
              onChange={handleChangeClient}
              options={clients}
              placeholder="Select Client"
            />
          </FormGroup>
        </Col>
      )}
      {withDates && startDate && endDate && (
        <>
          <Col md="3" sm="6" xs="6">
            <CardTitle>Start Date</CardTitle>
            <FormGroup>
              <ReactDatetime
                inputProps={{
                  className: 'form-control',
                  placeholder: 'Date Picker Here',
                }}
                onChange={e => handleChangeDates('start', e)}
                value={inputDate(startDate)}
                closeOnSelect
                timeFormat={false}
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="6" xs="6">
            <CardTitle>End Date</CardTitle>
            <FormGroup>
              <ReactDatetime
                inputProps={{
                  className: 'form-control',
                  placeholder: 'Date Picker Here',
                }}
                onChange={e => handleChangeDates('end', e)}
                value={inputDate(endDate)}
                closeOnSelect
                timeFormat={false}
              />
            </FormGroup>
          </Col>
        </>
      )}
    </>
  );
};
