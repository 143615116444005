import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import store from './store/create-store';
import { ENV_CONFIG } from './config/environment';
import routes from 'routes';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.3.1';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Auth0Provider
    domain={ENV_CONFIG().AUTH.DOMAIN}
    clientId={ENV_CONFIG().AUTH.CLIENT_ID}
    authorizationParams={{
      redirect_uri: `${ENV_CONFIG().SITE_URL}/dashboard`,
      audience: `https://${ENV_CONFIG().AUTH.DOMAIN}/api/v2/`,
      scope: 'read:current_user update:current_user_metadata',
    }}
  >
    <Provider store={store}>
      <RouterProvider router={createBrowserRouter(routes)} />
    </Provider>
  </Auth0Provider>
);
