import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const donorStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const donorSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const donorError = (state, { error }) => ({
  ...state,
  error: {
    donorError: error,
  },
  isLoading: false,
});

export const donorCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.DONOR_START]: donorStart,
  [Types.DONOR_SUCCESS]: donorSuccess,
  [Types.DONOR_ERROR]: donorError,
  [Types.DONOR_CLEAN_UP]: donorCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
