import { Transforms } from 'slate';
import { useSlate } from 'slate-react';
import Icon from 'components/Icon';
import { TEXT_ALIGN_TYPES } from './constants';
import { isBlockActive, isMarkActive, toggleBlock, toggleMark } from './utils';

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <b>{children}</b>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <i>{children}</i>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const LinkElement = ({ attributes, children, element }) => {
  const editor = useSlate();

  const updateUrl = () => {
    const url = window.prompt('Enter the URL');
    if (url) Transforms.setNodes(editor, { url }, { match: (n: any) => n.type === 'link' });
  };

  return (
    <span {...attributes}>
      <a href={element.url} onClick={updateUrl}>
        {children}
      </a>
    </span>
  );
};

export const SlateElement = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'link':
      return <LinkElement attributes={attributes} children={children} element={element} />;
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <div
      onClick={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon name={icon} size={16} color={isMarkActive(editor, format) ? '#000' : '#9A9A9A'} />
    </div>
  );
};

export const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <div
      onClick={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon
        name={icon}
        size={16}
        color={
          isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')
            ? '#000'
            : '#9A9A9A'
        }
      />
    </div>
  );
};
