import { ToastContainer, toast } from 'react-toastify';

export const Toast = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
};

export const showToast = (message: string, state: string, status?: number) => {
  const config = {
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (status === 501) return;

  if (state === 'error') return toast.error(message, config);
  else if (state === 'success') return toast.success(message, config);
  else if (state === 'warning') return toast.warning(message, config);
  return toast(message, config);
};
