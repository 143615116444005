import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const pagesStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const pagesSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const allPagesSuccess = (state, { data }) => ({
  ...state,
  allPages: data,
  isLoading: false,
});

export const pagesError = (state, { error }) => ({
  ...state,
  error: {
    pagesError: error,
  },
  isLoading: false,
});

export const pageCreatedSuccess = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    pageCreated: data,
  },
  isLoading: false,
});

export const pageCreatedError = (state, { error }) => ({
  ...state,
  error: {
    pageCreatedError: error,
  },
  isLoading: false,
});

export const pagesCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.PAGES_START]: pagesStart,
  [Types.ALL_PAGES_SUCCESS]: allPagesSuccess,
  [Types.PAGES_SUCCESS]: pagesSuccess,
  [Types.PAGES_ERROR]: pagesError,
  [Types.PAGE_CREATED_SUCCESS]: pageCreatedSuccess,
  [Types.PAGE_CREATED_ERROR]: pageCreatedError,
  [Types.PAGES_CLEAN_UP]: pagesCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
