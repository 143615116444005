import axios from 'axios';
import { Dispatch } from 'redux';
import DONATION_TYPES from './types';
import { showToast } from 'components/Toast';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';
import { getAccessToken } from 'services/storage';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getDonationTotal =
  (key: string, start_date: string, end_date: string, client_id: string, page?: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const pageTag = page ? `&page=${page}` : '';
    const clientID = client_id === 'None' ? '' : `&client_id=${client_id}`;

    dispatch(DONATION_TYPES.donationStart());
    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_DONATION_TOTAL(start_date, end_date, clientID, pageTag)}`;

    try {
      const response = await axios.get(url, {
        headers: { ...headers, Authorization: `Bearer ${getAccessToken()}` },
      });
      if (key === 'today') dispatch(DONATION_TYPES.donationTotalTodaySuccess(response.data));
      if (key === 'yesterday')
        dispatch(DONATION_TYPES.donationTotalYesterdaySuccess(response.data));
    } catch (error: any) {
      showToast(error.message, 'error', error?.response?.status);
      dispatch(DONATION_TYPES.donationTotalError(error.message));
    }
  };

export const getDonationTrend =
  (start_date: string, end_date: string, client_id: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(DONATION_TYPES.donationStart());
    const clientID = client_id === 'None' ? '' : `&client_id=${client_id}`;
    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_DONATION_TREND(start_date, end_date, clientID)}`;

    try {
      const response = await axios.get(url, {
        headers: { ...headers, Authorization: `Bearer ${getAccessToken()}` },
      });
      dispatch(DONATION_TYPES.donationTrendSuccess(response.data));
    } catch (error: any) {
      showToast(error.message, 'error', error?.response?.status);
      dispatch(DONATION_TYPES.donationTrendError(error.message));
    }
  };
