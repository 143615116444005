import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { IRecipientState, IRecipients } from 'store/recipients/types';

export const getRecipientsState = (state: RootState): IRecipientState => state.recipients;

export const getRecipientsData = createSelector(
  [getRecipientsState],
  (state: IRecipientState): IRecipients[] => state.data?.recipients
);
