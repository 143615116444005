import Login from 'views/Login';
import Pages from 'views/Pages';
import Dashboard from 'views/Dashboard';
import DonorList from 'views/DonorList';
import PagesDetail from 'views/PagesDetail';
import NotFoundScreen from 'views/NotFoundScreen';

const routes = [
  {
    path: '/',
    name: 'Login',
    mini: 'L',
    element: <Login />,
    errorElement: <NotFoundScreen />,
  },
  {
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    collapse: true,
    children: [
      {
        path: '/dashboard',
        name: 'Main',
        mini: 'M',
        element: <Dashboard />,
        permission: 'DASHBOARD_MAIN_ROUTE',
      },
      {
        path: '/donation-list',
        name: 'Donation List',
        mini: 'DL',
        element: <DonorList />,
        permission: 'DASHBOARD_DONOR_LIST_ROUTE',
      },
    ],
    permission: 'DASHBOARD_ROUTE',
  },
  {
    name: 'Pages',
    path: '/pages',
    icon: 'nc-icon nc-book-bookmark',
    permission: 'PAGES_ROUTE',
    element: <Pages />,
  },
  {
    name: 'PagesDetail',
    path: '/pages_detail',
    icon: 'nc-icon nc-book-bookmark',
    permission: 'PAGES_DETAIL_ROUTE',
    redirect: true,
    element: <PagesDetail />,
  },
];

export default routes;
