import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
} from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { ENV_CONFIG } from 'config/environment';
import { getIsSuper, getIsDev } from 'config/permissions';
import { Toast } from 'components/Toast';
import { useAppSelector } from 'helpers/hooks';
import { getUserData } from 'selectors/user';
import { clearLocalStorage, setRoleType, getRoleType, setAccessToken } from 'services/storage';

const AdminNavbar = props => {
  const { logout } = useAuth0();
  const location = useLocation();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [color, setColor] = useState('navbar-transparent');

  const userData = useAppSelector(getUserData);

  useEffect(() => {
    window.addEventListener('resize', updateColor);
  });

  useEffect(() => {
    if (window.outerWidth < 993 && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
    }
  }, [location]);
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor('bg-white');
    } else {
      setColor('navbar-transparent');
    }
  };
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    setSidebarOpen(!sidebarOpen);
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  const toggleCollapse = () => {
    if (!collapseOpen) {
      setColor('bg-white paddingTop');
    } else {
      setColor('navbar-transparent');
    }
    setCollapseOpen(!collapseOpen);
  };

  const handleLogout = () => {
    clearLocalStorage();
    logout({ logoutParams: { returnTo: ENV_CONFIG().SITE_URL } });
  };

  const switchEnvironments = () => {
    if (getRoleType()?.includes(process.env.REACT_APP_PRODUCTION || '')) {
      if (getRoleType() === process.env.REACT_APP_SUPER_ADMIN_PRODUCTION || '')
        setRoleType(process.env.REACT_APP_SUPER_ADMIN_STAGING || '');
      else setRoleType(process.env.REACT_APP_DEV_ADMIN_STAGING || '');
    } else {
      if (getRoleType() === process.env.REACT_APP_SUPER_ADMIN_STAGING || '')
        setRoleType(process.env.REACT_APP_SUPER_ADMIN_PRODUCTION || '');
      else setRoleType(process.env.REACT_APP_DEV_ADMIN_PRODUCTION || '');
    }
    setAccessToken('');
    window.location.reload();
  };

  return (
    <>
      <Navbar className={classnames('navbar-absolute fixed-top', color)} expand="lg">
        <Toast />
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-icon btn-round"
                color="default"
                id="minimizeSidebar"
                onClick={props.handleMiniClick}
              >
                <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
              </Button>
            </div>
            <div
              className={classnames('navbar-toggle', {
                toggled: sidebarOpen,
              })}
            >
              <button className="navbar-toggler" onClick={toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#" onClick={e => e.preventDefault()}>
              <span className="d-none d-md-block">{userData?.client_name}</span>
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse className="justify-content-end" navbar isOpen={collapseOpen}>
            <Nav navbar>
              <UncontrolledDropdown className="btn-rotate" nav>
                <DropdownToggle
                  aria-haspopup={true}
                  caret
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                >
                  <i className="nc-icon nc-settings-gear-65" />
                  <p>
                    <span className="d-lg-none d-md-block">Settings</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu persist aria-labelledby="navbarDropdownMenuLink" right>
                  {(getIsSuper() || getIsDev()) && (
                    <>
                      <DropdownItem href="#" onClick={switchEnvironments}>
                        Switch to{' '}
                        {getRoleType()?.includes(process.env.REACT_APP_PRODUCTION || '')
                          ? 'Staging'
                          : 'Production'}
                      </DropdownItem>
                      <DropdownItem divider />
                    </>
                  )}
                  <DropdownItem href="#" onClick={handleLogout}>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
