import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { IUserData, IUserState } from 'store/user/types';

export const getUserState = (state: RootState): IUserState => state.user;

export const getUserData = createSelector(
  [getUserState],
  (state: IUserState): IUserData => state.data
);
