import { createActions } from 'reduxsauce';

export interface IDonorState {
  data: IDonorData;
  isLoading: boolean;
  error: string;
}
export interface IDonorData {
  donors_totals: IDonorTotal[];
}
export interface IDonorTotal {
  donation_amount: number;
  donors: number;
  name: string;
  page: string;
  ref_code: string;
  time: string;
  transaction_id: string;
  user_city: string;
  user_email: string;
  user_state: string;
}

export interface DonorTypes {
  DONOR: 'DONOR';
  DONOR_START: 'DONOR_START';
  DONOR_SUCCESS: 'DONOR_SUCCESS';
  DONOR_ERROR: 'DONOR_ERROR';
  DONOR_CLEAN_UP: 'DONOR_CLEAN_UP';
}

const { Types, Creators } = createActions<DonorTypes>({
  donor: ['data'],
  donorStart: null,
  donorSuccess: ['data'],
  donorError: ['error'],
  donorCleanUp: null,
});

export { Types };

export default Creators;
