import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { IDonorState, IDonorTotal } from 'store/donor/types';

export const getDonorState = (state: RootState): IDonorState => state.donor;

export const getDonorData = createSelector(
  [getDonorState],
  (state: IDonorState): IDonorTotal[] => state?.data?.donors_totals
);
