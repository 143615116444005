import { ENV_CONFIG } from 'config/environment';
import { getClientID, getRoleType } from 'services/storage';

const permissions = [
  { key: 'COMPONENTS_ROUTE', super_admin: '', dev_admin: '', partner_admin: '' },
  { key: 'DASHBOARD_ROUTE', super_admin: 'CRUD', dev_admin: 'CRUD', partner_admin: 'CRU' },
  { key: 'DASHBOARD_MAIN_ROUTE', super_admin: 'CRUD', dev_admin: 'CRUD', partner_admin: 'CRU' },
  {
    key: 'DASHBOARD_DONOR_LIST_ROUTE',
    super_admin: 'CRUD',
    dev_admin: 'CRUD',
    partner_admin: 'CRU',
  },
  { key: 'DEV_PERMISSIONS', super_admin: 'CRUD', dev_admin: 'CRUD', partner_admin: '' },
  { key: 'PAGES_ROUTE', super_admin: 'CRUD', dev_admin: 'CRUD', partner_admin: 'CRU' },
  { key: 'PAGES_ROUTE_ALL', super_admin: 'R', dev_admin: 'R', partner_admin: '' },
  { key: 'PAGES_DETAIL_ROUTE', super_admin: 'CRUD', dev_admin: 'CRUD', partner_admin: 'CRU' },
  { key: 'PROD_PERMISSIONS', super_admin: 'CRUD', dev_admin: '', partner_admin: 'CRU' },
  { key: 'USER_PROFILE_ROUTE', super_admin: '', dev_admin: '', partner_admin: '' },
];

export const has_permission = (key: string, action: string) => {
  let keyPermission = key;
  if (!key) return false;
  if (key === 'SCREEN_VIEW_PERMISSION') {
    if (getIsDev()) {
      if (ENV_CONFIG().MODE === 'PRODUCTION') return false;
      else keyPermission = 'DEV_PERMISSIONS';
    } else keyPermission = 'PROD_PERMISSIONS';
  }
  if (key === 'PAGES_ROUTE') {
    if (getClientID() === 'None') keyPermission = 'PAGES_ROUTE_ALL';
  }
  const permission = permissions.find(permission => permission.key === keyPermission);
  if (getIsSuper()) return permission?.super_admin.includes(action);
  else if (getIsDev()) return permission?.dev_admin.includes(action);
  else return permission?.partner_admin.includes(action);
};

export const getIsSuper = () => {
  return (
    getRoleType() === process.env.REACT_APP_SUPER_ADMIN_STAGING ||
    getRoleType() === process.env.REACT_APP_SUPER_ADMIN_PRODUCTION
  );
};

export const getIsDev = () => {
  return (
    getRoleType() === process.env.REACT_APP_DEV_ADMIN_STAGING ||
    getRoleType() === process.env.REACT_APP_DEV_ADMIN_PRODUCTION
  );
};
