import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const transactionStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const transactionTotalTodaySuccess = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    today: data.transactions_total,
  },
  isLoading: false,
});

export const transactionTotalYesterdaySuccess = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    yesterday: data.transactions_total,
  },
  isLoading: false,
});

export const transactionTotalError = (state, { error }) => ({
  ...state,
  error: {
    transactionTotalError: error,
  },
  isLoading: false,
});

export const transactionCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.TRANSACTION_START]: transactionStart,
  [Types.TRANSACTION_TOTAL_TODAY_SUCCESS]: transactionTotalTodaySuccess,
  [Types.TRANSACTION_TOTAL_YESTERDAY_SUCCESS]: transactionTotalYesterdaySuccess,
  [Types.TRANSACTION_TOTAL_ERROR]: transactionTotalError,
  [Types.TRANSACTION_CLEAN_UP]: transactionCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
