import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

const NotFoundScreen = () => {
  const navigate = useNavigate();
  document.body.style.backgroundColor = '#EAF6FF';

  const handleSubmit = () => {
    document.body.style.backgroundColor = '#FFF';
    navigate('/');
  };

  return (
    <>
      <div className="notfound_container">
        <span className="notfound_title">Sorry, page not found</span>
        <br />
        <br />
        <span className="notfound_description">
          We can’t seem to find the page you’re looking for.
          <br />
          Let's get you on the right page.
        </span>
        <br />
        <br />
        <Button block className="btn-round mb-3" color="warning" onClick={handleSubmit}>
          GO HOME
        </Button>
      </div>
    </>
  );
};

export default NotFoundScreen;
