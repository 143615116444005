import { createActions } from 'reduxsauce';

export interface ISummaryState {
  data: ISummaryData;
  isLoading: boolean;
  error: string;
}
export interface ISummaryData {
  summary_totals: ISummaryTotals[];
}
export interface ISummaryTotals {
  donors: number;
  recipient: string;
  total: number;
}

export interface SummaryTypes {
  SUMMARY: 'SUMMARY';
  SUMMARY_START: 'SUMMARY_START';
  SUMMARY_SUCCESS: 'SUMMARY_SUCCESS';
  SUMMARY_ERROR: 'SUMMARY_ERROR';
  SUMMARY_CLEAN_UP: 'SUMMARY_CLEAN_UP';
}

const { Types, Creators } = createActions<SummaryTypes>({
  summary: ['data'],
  summaryStart: null,
  summarySuccess: ['data'],
  summaryError: ['error'],
  summaryCleanUp: null,
});

export { Types };

export default Creators;
