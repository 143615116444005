export interface ITableColumn {
  Header: string;
  accessor: string;
  sortable?: boolean;
  filterable?: boolean;
  visible: boolean;
}

export const pageColumns = [
  { Header: 'Page Name', accessor: 'pageName', visible: true },
  { Header: 'Website URL', accessor: 'webUrl', visible: true },
  { Header: 'Donation Amounts', accessor: 'amounts', visible: true },
  { Header: 'Actions', accessor: 'actions', visible: true, sortable: false, filterable: false },
];

export const donorColumns = [
  { Header: 'Donation Date', accessor: 'date', visible: true },
  { Header: 'Donor Name', accessor: 'name', visible: true },
  { Header: 'Amount Donated', accessor: 'amount', visible: true },
  { Header: 'Page', accessor: 'page', visible: true },
  { Header: 'Reference Code', accessor: 'ref', visible: true },
  { Header: 'Email', accessor: 'email', visible: false },
  { Header: 'City', accessor: 'city', visible: false },
  { Header: 'State', accessor: 'state', visible: false },
];

export const buttonTypes = {
  edit: { color: 'info', icon: 'fa fa-edit' },
  disable: { color: 'danger', icon: 'fa fa-trash' },
  redirect: { color: 'success', icon: 'fa fa-eye' },
  duplicate: { color: 'warning', icon: 'fa fa-copy' },
};
