import { useEffect, useRef, useState } from 'react';
import routes from 'routes';
import { Row, CardTitle, Col } from 'reactstrap';
import { getIsSuper, getIsDev, has_permission } from 'config/permissions';
import { donorColumns, ITableColumn } from 'constants/tables';
import Footer from 'components/Footer';
import Loader from 'components/Loader';
import Sidebar from 'components/Sidebar/Sidebar';
import { CustomTable } from 'components/CustomTable';
import { CustomHeader } from 'components/CustomHeader';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { numberWithDot } from 'helpers/utils';
import { urlDate, tableDate } from 'helpers/date';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getUserState } from 'selectors/user';
import { getDonorData, getDonorState } from 'selectors/donors';
import { getClientID, getAccessToken } from 'services/storage';
import { getTableDonors } from 'store/donor/actions';

interface IData {
  id: number;
  date: string;
  name: string;
  amount: string;
  page: string;
  ref: string;
  email: string;
  city: string;
  state: string;
}
const DonorList = props => {
  const mainPanel = useRef<any>();
  const dispatch = useAppDispatch();
  const [dataState, setDataState] = useState(Array<IData>());
  const [tableColumns, setTableColumns] = useState<ITableColumn[]>(donorColumns);

  const { donors, donorsState, userState } = useAppSelector(state => ({
    donors: getDonorData(state),
    donorsState: getDonorState(state),
    userState: getUserState(state),
  }));

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  useEffect(() => {
    if (getClientID() && userState.dates?.start && userState.dates?.end && getAccessToken()) {
      dispatch(
        getTableDonors(
          urlDate(userState.dates.start, 'start'),
          urlDate(userState.dates.end, 'end'),
          getClientID() || ''
        )
      );
    }
  }, [userState]);

  useEffect(() => {
    if (donors) {
      setDataState(
        donors?.map((prop, key) => {
          return {
            id: key,
            date: tableDate(prop.time),
            name: prop.name,
            amount: `$${prop.donation_amount}`,
            page: prop.page,
            ref: prop.ref_code,
            city: prop.user_city,
            email: prop.user_email,
            state: prop.user_state,
          };
        })
      );
    }
  }, [donors]);

  return (
    <>
      {donorsState.isLoading && <Loader type="dots" />}
      <div className="wrapper">
        <Sidebar {...props} routes={routes} bgColor="black" activeColor="info" />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar {...location} handleMiniClick={handleMiniClick} />
          {!has_permission('SCREEN_VIEW_PERMISSION', 'R') ? (
            <div className="content">
              <h1 className="title-permission">
                Sorry, you don't have permission to see this screen. Switch the environment to
                staging.
              </h1>
            </div>
          ) : (
            <div className="content">
              <Row>
                <Col md={getIsSuper() || getIsDev() ? '4' : '6'} sm="12">
                  <h1 className="title-dashboard with-subtitle">Impact Dashboard</h1>
                  {donors && (
                    <CardTitle className="subtitle-dashboard" tag="h4">
                      {donors.length} Donations &nbsp; $
                      {numberWithDot(
                        donors.reduce((acum, item) => acum + item.donation_amount, 0).toFixed(2)
                      )}
                    </CardTitle>
                  )}
                </Col>
                <CustomHeader withDates />
              </Row>
              <Row>
                <Col lg="12">
                  <CustomTable
                    title="Most Recent Donations"
                    subtitle="Listed in order of donation date"
                    data={dataState}
                    columns={tableColumns}
                    withPagination
                    withDownloadCSV
                    withColumnFilter
                    updateColumns={setTableColumns}
                  />
                </Col>
              </Row>
            </div>
          )}
          <Footer fluid />
        </div>
      </div>
    </>
  );
};

export default DonorList;
