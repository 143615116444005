import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { ISummaryState, ISummaryTotals } from 'store/summary/types';

export const getSummaryState = (state: RootState): ISummaryState => state.summary;

export const getSummaryData = createSelector(
  [getSummaryState],
  (state: ISummaryState): ISummaryTotals[] => state.data?.summary_totals
);
