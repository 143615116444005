import { useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {
  const fullPages = useRef<any>();
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    document.body.classList.toggle('login-page');
    return function cleanup() {
      document.body.classList.toggle('login-page');
    };
  });

  useEffect(() => {
    loginWithRedirect();
  }, []);

  return (
    <div className="wrapper wrapper-full-page" ref={fullPages}>
      <div className="full-page section-image">
        <div className="login-page">
          <div
            className="full-page-background"
            style={{
              backgroundColor: '#000000',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
