import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const donationStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const donationTrendSuccess = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    donationTrend: data.donation_trend,
  },
  isLoading: false,
});

export const donationTrendError = (state, { error }) => ({
  ...state,
  error: {
    ...state.error,
    donationTrendError: error,
  },
  isLoading: false,
});

export const donationTotalTodaySuccess = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    today: data.donation_total,
  },
  isLoading: false,
});

export const donationTotalYesterdaySuccess = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    yesterday: data.donation_total,
  },
  isLoading: false,
});

export const donationTotalError = (state, { error }) => ({
  ...state,
  error: {
    ...state.error,
    donationTotalError: error,
  },
  isLoading: false,
});

export const donationCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.DONATION_START]: donationStart,
  [Types.DONATION_TREND_SUCCESS]: donationTrendSuccess,
  [Types.DONATION_TREND_ERROR]: donationTrendError,
  [Types.DONATION_TOTAL_TODAY_SUCCESS]: donationTotalTodaySuccess,
  [Types.DONATION_TOTAL_YESTERDAY_SUCCESS]: donationTotalYesterdaySuccess,
  [Types.DONATION_TOTAL_ERROR]: donationTotalError,
  [Types.DONATION_CLEAN_UP]: donationCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
