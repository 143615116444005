import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { ITransactionData, ITransactionState } from 'store/transaction/types';

export const getTransactionState = (state: RootState): ITransactionState => state.transaction;

export const getTotalTransactionData = createSelector(
  [getTransactionState],
  (state: ITransactionState): ITransactionData => state?.data
);
