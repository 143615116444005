import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const userStart = state => ({
  ...state,
  isLoading: true,
  error: null,
  datesUpdated: false,
});

export const userSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const userError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const userClientID = (state, { data }) => ({
  ...state,
  clientID: data,
  isLoading: false,
  datesUpdated: false,
});

export const userClients = (state, { data }) => ({
  ...state,
  clients: data,
  isLoading: false,
  datesUpdated: false,
});

export const userDates = (state, { data }) => ({
  ...state,
  dates: {
    start: data.start,
    end: data.end,
  },
  isLoading: false,
  datesUpdated: data.isUpdated,
});

export const userCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.USER_START]: userStart,
  [Types.USER_SUCCESS]: userSuccess,
  [Types.USER_ERROR]: userError,
  [Types.USER_CLIENT_ID]: userClientID,
  [Types.USER_CLIENTS]: userClients,
  [Types.USER_DATES]: userDates,
  [Types.USER_CLEAN_UP]: userCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
