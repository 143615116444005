export const numberWithDot = (x: number | string) => {
  const withComma = x?.toString().replace(',', '.');

  return withComma?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ' ...';
};

export const percentDiff = (val1: number, val2: number) => {
  if (val1 && val2) return ((val1 * 100) / val2 - 100).toFixed(2);
  return '-';
};

export const sumAmounts = (array: number[]) => {
  return array?.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
};

export const limitChars = (value, limit) => {
  if (value?.length > limit) {
    return `${value.substring(0, limit)}...`;
  } else {
    return value;
  }
};

export const transformToUrl = (value: string) => {
  return value.replace(/ /g, '-');
};

export function wait(timeout) {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
}

export const getUrlSearchParamsObj = () => new URL(window.location.href).searchParams;

export const getUrlParams = () => {
  const searchParams = getUrlSearchParamsObj();

  return {
    tagId: searchParams?.get('tag_id'),
    copy: searchParams?.get('copy'),
  };
};

export const isKeyInObject = (key: string, obj: object) => Object.keys(obj).includes(key);
