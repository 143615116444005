import axios from 'axios';
import { Dispatch } from 'redux';
import RECIPIENTS_TYPES, { RecipientProps } from './types';
import { ENV_CONFIG } from 'config/environment';
import { showToast } from 'components/Toast';
import API_ROUTES from 'services/apiRoutes';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getRecipients =
  ({ search_str, recipient_ids }: RecipientProps) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(RECIPIENTS_TYPES.recipientsStart());

    let rec_params = '';
    let search_str_params = '';
    if (search_str) search_str_params = `?search_str=${search_str}`;
    recipient_ids?.forEach((item, index) => {
      if (index === 0 && !search_str) rec_params += `?recipient_ids=${item}`;
      else rec_params += `&recipient_ids=${item}`;
    });

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_RECIPIENTS(
      search_str_params,
      rec_params
    )}`;
    try {
      const response = await axios.get(url, {
        headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
      });
      dispatch(RECIPIENTS_TYPES.recipientsSuccess(response.data));
    } catch (error: any) {
      showToast(error.message, 'error', error?.response?.status);
      dispatch(RECIPIENTS_TYPES.recipientsError(error.message));
    }
  };
