import { createActions } from 'reduxsauce';

export interface IUserState {
  data: IUserData;
  clientID: string;
  clients: { id: string; name: string }[];
  dates: {
    start: number;
    end: number;
  };
  isLoading: boolean;
  error: string;
  datesUpdated: boolean;
}
export interface IUserData {
  name: string;
  client_name: string;
}

export interface UserTypes {
  USER: 'USER';
  USER_START: 'USER_START';
  USER_SUCCESS: 'USER_SUCCESS';
  USER_ERROR: 'USER_ERROR';
  USER_CLIENT_ID: 'USER_CLIENT_ID';
  USER_CLIENTS: 'USER_CLIENTS';
  USER_DATES: 'USER_DATES';
  USER_CLEAN_UP: 'USER_CLEAN_UP';
}

const { Types, Creators } = createActions<UserTypes>({
  user: ['data'],
  userStart: null,
  userSuccess: ['data'],
  userError: ['error'],
  userClientID: ['data'],
  userClients: ['data'],
  userDates: ['data'],
  userCleanUp: null,
});

export { Types };

export default Creators;
