const API_ROUTES = {
  GET_DONATION_TOTAL: (start_date: string, end_date: string, client_id: string, page: string) =>
    `/admin/donation/total?start_date=${start_date}&end_date=${end_date}${client_id}${page}`,
  GET_DONATION_TREND: (start_date: string, end_date: string, client_id: string) =>
    `/admin/donation/trend?start_date=${start_date}&end_date=${end_date}${client_id}`,
  GET_PAGES: (client_id: string) => `/admin/pages?client_id=${client_id}`,
  GET_RECIPIENTS: (search_str: string, rec_param: string) => `/recipients${search_str}${rec_param}`,
  GET_TAGS: (query_str: string) => `/tags${query_str}`,
  GET_TABLE_DONORS: (start_date: string, end_date: string, client_id: string, page: string) =>
    `/admin/table/donors?start_date=${start_date}&end_date=${end_date}${client_id}${page}`,
  GET_TABLE_SUMMARY: (
    start_date: string,
    end_date: string,
    dimension: string,
    client_id: string,
    page: string
  ) =>
    `/admin/table/summary?start_date=${start_date}&end_date=${end_date}&dimension=${dimension}${client_id}${page}`,
  GET_TRANSACTION_TOTAL: (start_date: string, end_date: string, client_id: string) =>
    `/admin/transactions?start_date=${start_date}&end_date=${end_date}${client_id}`,
  GET_USER: (email: string) => `/user?email=${email}`,
  GOOGLE_STORAGE_URL: (filename: string) => `/admin/google_storage_url?filename=${filename}`,
  PAGES: '/admin/pages',
};

export default API_ROUTES;
