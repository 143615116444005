import { FC } from 'react';
import Lottie from 'react-lottie';
import * as animationSpinner from '../../assets/img/spinner.json';
import * as animationDots from '../../assets/img/loader_dots.json';

interface Props {
  type: 'spinner' | 'dots';
  height?: number;
  width?: number;
}

const Loader: FC<Props> = ({ type, height, width }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: type === 'spinner' ? animationSpinner : animationDots,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return type === 'spinner' ? (
    <div style={{ zIndex: 1, width, height }}>
      <Lottie options={defaultOptions} />
    </div>
  ) : (
    <div
      style={{
        position: 'absolute',
        textAlign: 'center',
        zIndex: 1,
        width: '100%',
        height: '100%',
        paddingTop: 200,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      }}
    >
      <Lottie options={defaultOptions} height={400} width={400} />
    </div>
  );
};

export default Loader;
