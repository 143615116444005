import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { IDonationData, IDonationState, ITrendDonationData } from 'store/donation/types';

export const getDonationState = (state: RootState): IDonationState => state.donation;

export const getTotalDonationData = createSelector(
  [getDonationState],
  (state: IDonationState): IDonationData => state?.data
);

export const getTrendDonationData = createSelector(
  [getDonationState],
  (state: IDonationState): ITrendDonationData[] => state?.data?.donationTrend
);
