import { FC, ReactElement } from 'react';
import { Badge, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

interface Props {
  title: string;
  subtitle: string;
  titleValue: string;
  subtitleValue: string;
  badgeStyle: string;
  badgeValue: string;
  icon: ReactElement;
}
export const SmallCard: FC<Props> = ({
  title,
  subtitle,
  titleValue,
  subtitleValue,
  badgeStyle,
  badgeValue,
  icon,
}) => {
  return (
    <Col lg="12" md="12" sm="12">
      <Card className="card-stats">
        <CardBody>
          <Row>
            <Col md="3" xs="2">
              <div className="icon-big text-center icon-warning">{icon}</div>
            </Col>
            <Col md="9" xs="10">
              <div className="numbers">
                <p className="card-category">{title}</p>
                <CardTitle tag="p">{titleValue}</CardTitle>
                <p />
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardBody>
          <hr />
          <Row>
            <Col md="3" xs="2" style={{ alignSelf: 'flex-end' }}>
              <Badge className={`big-badge ${badgeStyle}`} pill>
                {badgeValue}
              </Badge>
            </Col>
            <Col md="9" xs="10">
              <div className="numbers">
                <p className="card-subcategory">{subtitle}</p>
                <CardTitle tag="p" className="card-subcategory-value">
                  {subtitleValue}
                </CardTitle>
                <p />
              </div>
            </Col>
          </Row>
        </CardBody>
        <br />
      </Card>
    </Col>
  );
};
