import { Dispatch, SetStateAction, useState } from 'react';
import Select from 'react-select';
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Row, Table } from 'reactstrap';
import { tableDate } from 'helpers/date';
import { numberWithDot } from 'helpers/utils';

interface Props<T> {
  type: string;
  title: string;
  subtitle: string;
  columns: string[];
  data: T[];
  dropdownValue?: { value: number; label: string };
  dropdownOnChange?: Dispatch<SetStateAction<{ value: number; label: string }>>;
  dropdownOptions?: { value: number; label: string }[];
  dropdownPlaceholder?: string;
  withPagination?: boolean;
}

export default function RegularTable<
  T extends {
    total?: number;
    donors?: number;
    time?: string;
    name?: string;
    donation_amount?: number;
    ref_code?: string;
  },
>({
  type,
  title,
  subtitle,
  columns,
  data,
  dropdownValue,
  dropdownOnChange,
  dropdownOptions,
  dropdownPlaceholder,
  withPagination,
}: Props<T>) {
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState<number>(0);

  const sortValues = (item: T) => {
    if (type === 'summary')
      return [item[columns[0]], `$${numberWithDot(item.total?.toFixed(2) || 0)}`, item.donors];
    if (type === 'donors')
      return [tableDate(item.time), item.name, `$${item.donation_amount}`, item.ref_code];
  };

  return (
    <Card>
      <CardHeader>
        {dropdownValue ? (
          <Row>
            <Col lg="6" xs="6">
              <CardTitle tag="h4">{title}</CardTitle>
              <CardSubtitle>{subtitle}</CardSubtitle>
            </Col>
            <Col lg="6" xs="6">
              <div style={{ paddingTop: 10 }}>
                <Select
                  className="react-select primary react-select-dropdown"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  value={dropdownValue}
                  onChange={dropdownOnChange}
                  options={dropdownOptions}
                  placeholder={dropdownPlaceholder}
                />
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <CardTitle tag="h4">{title}</CardTitle>
            <CardSubtitle>{subtitle}</CardSubtitle>
          </>
        )}
      </CardHeader>
      <CardBody>
        <Table responsive>
          <thead>
            <tr>
              {columns.map((col: string, i: number) => (
                <th key={i} className={columns.length === i + 1 ? 'text-right' : ''}>
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.slice(currentPage * 5, currentPage * 5 + 5)?.map((item: T, i: number) => (
              <tr key={i}>
                {sortValues(item)?.map((val, j) => (
                  <td key={j} className="regular-table-body">
                    {val || '-'}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
        {withPagination && (
          <div className="ReactTable primary-pagination">
            <div className="pagination-bottom">
              <div className="-pagination">
                <Col lg="6" style={{ margin: '8px 0' }}>
                  <div className="-previous">
                    <button
                      type="button"
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 0}
                      className="-btn"
                    >
                      Previous
                    </button>
                  </div>
                </Col>
                <Col lg="6" style={{ margin: '8px 0' }}>
                  <div className="-next">
                    <button
                      type="button"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage === Math.ceil(data?.length / pageSize)}
                      className="-btn"
                    >
                      Next
                    </button>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
}
