import { useEffect, useRef, useState } from 'react';
import routes from 'routes';
import {
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Input,
  CardFooter,
} from 'reactstrap';
import Select from 'react-select';
import TagsInput from 'react-tagsinput';
import Switch from 'react-bootstrap-switch';
import { useNavigate } from 'react-router-dom';
import { ENV_CONFIG } from 'config/environment';
import { getIsSuper, has_permission } from 'config/permissions';
import Loader from 'components/Loader';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import { SlateInput } from 'components/SlateInput';
import { showToast } from 'components/Toast';
import { SlateNode } from 'components/SlateInput/types';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { convertToHTML } from 'components/SlateInput/utils';
import { websiteWithLines } from 'helpers/regexp';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { transformToUrl, wait, getUrlParams } from 'helpers/utils';
import { getPagesState } from 'selectors/pages';
import { getRecipientsState } from 'selectors/recipients';
import { getAccessToken, getClientID } from 'services/storage';
import { IPage } from 'store/pages/types';
import { getRecipients } from 'store/recipients/actions';
import { getPages, createPage, updatePage, uploadFile, getAllPages } from 'store/pages/actions';

interface IRecipient {
  value: string;
  label: string;
}
const PagesDetail = props => {
  const mainPanel = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isPageDuplicated, setIsPageDuplicated] = useState(false);
  const [editPageSelected, setEditPageSelected] = useState<IPage[]>();
  const [tagName, setTagName] = useState('');
  const [tagNameState, setTagNameState] = useState('');
  const [checkingTagName, setCheckingTagName] = useState(false);
  const [tagNameInUse, setTagNameInUse] = useState(false);
  const [queryString, setQueryString] = useState('');
  const [queryStringState, setQueryStringState] = useState('');
  const [checkingWebsite, setCheckingWebsite] = useState(false);
  const [websiteInUse, setWebsiteInUse] = useState(false);
  const [tagDescription, setTagDescription] = useState('');
  const [tagDescriptionState, setTagDescriptionState] = useState('');
  const [donationAmounts, setDonationAmounts] = useState([10, 25, 50, 100, 250]);
  const [donationAmountsState, setDonationAmountsState] = useState('');
  const [recipientsSelected, setRecipientsSelected] = useState(Array<IRecipient>());
  const [recipients, setRecipients] = useState(Array<IRecipient>());
  const [isUpdatingRecipients, setIsUpdatingRecipients] = useState(false);
  const [tagCTA, setTagCTA] = useState('');
  const [bgColor, setBgColor] = useState<string | null>(null);
  const [textColor, setTextColor] = useState<string | null>(null);
  const [impactScoreVisibility, setImpactScoreVisibility] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [preview, setPreview] = useState<string | null>();
  const [pageTypeView, setPageTypeView] = useState('LIST');

  const { recipientsState, pagesState } = useAppSelector(state => ({
    recipientsState: getRecipientsState(state),
    pagesState: getPagesState(state),
  }));

  useEffect(() => {
    if (getClientID() && getAccessToken()) {
      dispatch(getPages(getClientID() || ''));
      dispatch(getAllPages());
    }
  }, []);

  useEffect(() => {
    if (!pagesState.isLoading) {
      if (pagesState.data?.pageCreated) {
        showToast(
          `Success! Your page has been ${editPageSelected ? 'updated' : 'created'}`,
          'success'
        );
        if (selectedFile) {
          const customName = editPageSelected
            ? `tag_${editPageSelected[0].tag.id}.png`
            : `tag_${pagesState.data.pageCreated.tag_id}.png`;
          const renamedFile = new File([selectedFile], customName, { type: selectedFile.type });
          dispatch(uploadFile(renamedFile));
        }
        wait(2000).then(() => navigate('/pages'));
      }
      if (pagesState.data?.pages?.length > 0 && !editPageSelected && getUrlParams()?.tagId) {
        const selected = pagesState.data.pages.filter(
          page => page.tag.id === parseInt(getUrlParams().tagId || '')
        );
        if (selected.length === 0) navigate('/pages');
        else setEditPageSelected(selected);
        if (getUrlParams().copy !== null) setIsPageDuplicated(true);
      }
    }
  }, [pagesState]);

  useEffect(() => {
    if (editPageSelected) {
      setTagName(
        isPageDuplicated ? `${editPageSelected[0].tag.value} Copy` : editPageSelected[0].tag.value
      );
      setQueryString(
        isPageDuplicated
          ? `${editPageSelected[0].tag.query_str}-copy`
          : editPageSelected[0].tag.query_str
      );
      setTagDescription(editPageSelected[0].tag.desc);
      setDonationAmounts(editPageSelected[0].tag.donation_amounts);
      setTagCTA(editPageSelected[0].tag.tag_cta);
      setBgColor(editPageSelected[0].tag.page_misc?.page_background);
      setTextColor(editPageSelected[0].tag.page_misc?.page_color_text);
      setImpactScoreVisibility(editPageSelected[0].tag.impact_score_visibility);
      setPageTypeView(editPageSelected[0].tag.page_type);
      setPreview(
        `${ENV_CONFIG().CLOUD_STORAGE.URL}/${ENV_CONFIG().CLOUD_STORAGE.TAGS}/tag_${editPageSelected[0].tag.id}.png`
      );
      setTagNameState('has-success');
      setQueryStringState('has-success');
      setTagDescriptionState('has-success');
      setIsUpdatingRecipients(true);
      dispatch(getRecipients({ recipient_ids: editPageSelected[0].recipient_ids }));
    }
  }, [editPageSelected]);

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  let timeoutId;
  const handleSearchRecipients = (search_str: string) => {
    if (search_str.length > 0) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(function () {
        dispatch(getRecipients({ search_str }));
      }, 500);
    }
  };

  useEffect(() => {
    if (!pagesState.isLoading && pagesState.allPages) {
      if (checkingWebsite) {
        setCheckingWebsite(false);
        if (
          pagesState.allPages?.pages?.some(
            item => item.tag.query_str.toLowerCase() === queryString.toLowerCase()
          )
        ) {
          setQueryStringState('has-danger');
          setWebsiteInUse(true);
        } else setWebsiteInUse(false);
      }
      if (checkingTagName) {
        if (editPageSelected && editPageSelected[0].tag.value === tagName) {
          setTagNameInUse(false);
        } else {
          const isTagNameInUse = pagesState.allPages?.pages?.some(
            item => item.tag.value.toLowerCase() === tagName.toLowerCase()
          );

          if (isTagNameInUse) {
            setTagNameState('has-danger');
            setTagNameInUse(true);
          } else {
            setTagNameInUse(false);
          }
        }
        setCheckingTagName(false);
      }
    }
  }, [checkingWebsite, checkingTagName]);

  useEffect(() => {
    if (!recipientsState.isLoading && recipientsState.data) {
      const arr = Array<IRecipient>();
      recipientsState.data?.recipients?.forEach(rec => {
        arr.push({ value: rec.recipient_id, label: rec.recipient_name });
      });
      if (isUpdatingRecipients) {
        setIsUpdatingRecipients(false);
        setRecipientsSelected(arr);
      } else setRecipients(arr);
    }
  }, [recipientsState]);

  const handleSubmit = () => {
    if (validForm) {
      const body = {
        tag: {
          value: tagName,
          query_str: queryString.toLowerCase(),
          desc: tagDescription,
          donation_amounts: donationAmounts,
          client_id: getClientID() || '',
          tag_cta: tagCTA,
          page_misc: {
            page_background: bgColor,
            page_color_text: textColor,
          },
          impact_score_visibility: impactScoreVisibility,
          is_enabled: true,
          page_type: pageTypeView,
        },
        recipient_ids: recipientsSelected?.map(item => item.value) || [],
      };
      if (editPageSelected && !isPageDuplicated) {
        const updateBody = {
          client_id: getClientID() || '',
          page: {
            ...body,
            tag: {
              ...editPageSelected[0].tag,
              ...body.tag,
              id: parseInt(getUrlParams().tagId || ''),
            },
          },
        };
        dispatch(updatePage(updateBody));
      } else dispatch(createPage(body));
    } else {
      showToast('Some required fields are missing', 'error');
    }
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview('');
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const validForm =
    tagNameState === 'has-success' &&
    queryStringState === 'has-success' &&
    tagDescriptionState === 'has-success' &&
    donationAmounts.length > 0;

  const handleChangeDescription = (nodes: SlateNode[]) => {
    setTagDescriptionState(nodes ? 'has-success' : 'has-danger');
    setTagDescription(convertToHTML(nodes));
  };

  const addDonationAmount = (values: number[]) => {
    const amounts = values.map(amount => (typeof amount === 'string' ? parseInt(amount) : amount));
    setDonationAmounts(amounts.sort((a, b) => a - b));
  };

  const resetToDefaultColors = () => {
    setBgColor(null);
    setTextColor(null);
  };

  return (
    <>
      {pagesState.isLoading && <Loader type="dots" />}
      <div className="wrapper">
        <Sidebar {...props} routes={routes} bgColor="black" activeColor="info" />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar {...location} handleMiniClick={handleMiniClick} />
          {!has_permission('SCREEN_VIEW_PERMISSION', 'R') ? (
            <div className="content">
              <h1 className="title-permission">
                Sorry, you don't have permission to see this screen. Switch the environment to
                staging.
              </h1>
            </div>
          ) : (
            <div className="content">
              <Col md="12">
                <Form id="RegisterValidation">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        {editPageSelected && !isPageDuplicated
                          ? 'Edit your existing page'
                          : 'Create a new page'}
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <FormGroup className={`has-label ${tagNameState}`}>
                        <label htmlFor="name-input">Page Name *</label>
                        <Input
                          id="name-input"
                          name="tag_name"
                          type="text"
                          value={tagName}
                          onBlur={() => setCheckingTagName(true)}
                          onChange={e => {
                            setTagNameState(e.target.value ? 'has-success' : 'has-danger');
                            setTagName(e.target.value);
                          }}
                          disabled={!getIsSuper() && editPageSelected && !isPageDuplicated}
                        />
                        {checkingTagName && <Loader type="spinner" width={20} height={20} />}
                        {tagNameState === 'has-danger' ? (
                          tagNameInUse ? (
                            <label className="error">This page name is already being used.</label>
                          ) : (
                            <label className="error">This field is required.</label>
                          )
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label ${queryStringState}`}>
                        <label htmlFor="querystring-input">Website URL *</label>
                        <Input
                          id="querystring-input"
                          name="query_string"
                          type="text"
                          value={queryString}
                          style={{ textTransform: 'lowercase' }}
                          onBlur={() => setCheckingWebsite(true)}
                          onChange={e => {
                            setQueryStringState(
                              e.target.value &&
                                websiteWithLines.test(e.target.value) &&
                                !e.target.value.endsWith('-')
                                ? 'has-success'
                                : 'has-danger'
                            );
                            setQueryString(transformToUrl(e.target.value));
                          }}
                          disabled={!!editPageSelected && !isPageDuplicated}
                        />
                        {queryStringState === 'has-danger' ? (
                          !websiteWithLines.test(queryString) || queryString.endsWith('-') ? (
                            <label className="error">This field has incorrect characters.</label>
                          ) : websiteInUse ? (
                            <label className="error">This URL is already being used.</label>
                          ) : (
                            <label className="error">This field is required.</label>
                          )
                        ) : null}
                        <div
                          className="category form-category"
                          style={{ textTransform: 'lowercase' }}
                        >
                          {checkingWebsite && <Loader type="spinner" width={20} height={20} />}
                          {`${ENV_CONFIG().PARTNER_PAGE}${queryString}`}
                        </div>
                      </FormGroup>
                      {((tagDescription && editPageSelected) || !editPageSelected) && (
                        <FormGroup className={`has-label ${tagDescriptionState}`}>
                          <label htmlFor="description-input">Page Description *</label>
                          <SlateInput
                            id="description-input"
                            value={tagDescription}
                            onChange={handleChangeDescription}
                          />
                          {tagDescriptionState === 'has-danger' ? (
                            <label className="error">This field is required.</label>
                          ) : null}
                        </FormGroup>
                      )}
                      <FormGroup
                        className={`has-label ${donationAmountsState} ${donationAmounts.length === 0 && 'has-danger'}`}
                      >
                        <label>Donation amounts *</label>
                        <br />
                        <TagsInput
                          value={donationAmounts}
                          onChange={value => {
                            setDonationAmountsState(value ? 'has-success' : 'has-danger');
                            addDonationAmount(value);
                          }}
                          tagProps={{ className: 'react-tagsinput-tag success' }}
                          inputProps={{
                            placeholder: 'Add amount',
                            className: 'react-tagsinput-input error',
                          }}
                          maxTags={10}
                          validate={value => value > 0}
                        />
                        <br />
                        {donationAmountsState === 'has-danger' ? (
                          <label className="error">This field must be numeric.</label>
                        ) : donationAmounts.length === 0 ? (
                          <label className="error">This field is required.</label>
                        ) : donationAmounts.length === 10 ? (
                          <label className="error">
                            You’ve reached the maximum number of donation amounts!.
                          </label>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={'has-label'}>
                        <label htmlFor="recipients-input">Recipients</label>
                        <Select
                          id="recipients-input"
                          className="react-select success"
                          classNamePrefix="react-select"
                          placeholder="Search"
                          name="recipientsSelected"
                          isMulti
                          isSearchable
                          isClearable={false}
                          value={recipientsSelected}
                          onChange={setRecipientsSelected}
                          onInputChange={handleSearchRecipients}
                          options={recipients}
                        />
                        <div className="category form-category" style={{ textTransform: 'none' }}>
                          Missing a recipient you’re looking for? Please complete your page creation
                          and email partners@oath.vote. Additional recipients can be added later.
                        </div>
                      </FormGroup>
                      <div className="category form-category">* Required fields</div>
                      <CardTitle tag="h4">Additional customization [optional]</CardTitle>
                      <FormGroup className={'has-label'}>
                        <label htmlFor="cta-input">Page Call to Action</label>
                        <Input
                          id="cta-input"
                          name="tag_cta"
                          type="text"
                          value={tagCTA}
                          onChange={e => setTagCTA(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className={'has-label'}>
                        <label htmlFor="bgcolor-input">Background color</label>
                        <input
                          id="bgcolor-input"
                          name="background_color"
                          type="color"
                          className="input-color"
                          value={bgColor || ''}
                          onChange={e => setBgColor(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className={'has-label'}>
                        <label htmlFor="textcolor-input">Text color</label>
                        <input
                          id="textcolor-input"
                          name="text_color"
                          type="color"
                          className="input-color"
                          value={textColor || ''}
                          onChange={e => setTextColor(e.target.value)}
                        />
                      </FormGroup>
                      <Button
                        color="info"
                        onClick={resetToDefaultColors}
                        disabled={!bgColor && !textColor}
                      >
                        Reset to default colors
                      </Button>
                      <FormGroup className={'has-label'}>
                        <label>Impact Score visibility</label>
                        <br />
                        <Switch
                          value={impactScoreVisibility}
                          onColor="success"
                          onText="ON"
                          offColor="success"
                          offText="OFF"
                          handleWidth={500}
                          onChange={e => setImpactScoreVisibility(e.state.value)}
                        />
                      </FormGroup>
                      <FormGroup tag="fieldset">
                        <label>Page type view</label>
                        <FormGroup check>
                          <Input
                            name="radio1"
                            type="radio"
                            checked={pageTypeView === 'LIST'}
                            onClick={() => setPageTypeView('LIST')}
                          />
                          <label>LIST</label>
                        </FormGroup>
                        <FormGroup check>
                          <Input
                            name="radio1"
                            type="radio"
                            checked={pageTypeView === 'COMPACT'}
                            onClick={() => setPageTypeView('COMPACT')}
                          />
                          <label>COMPACT</label>
                        </FormGroup>
                      </FormGroup>
                      <FormGroup className={'has-label'}>
                        <label>Custom Image</label>
                        <br />
                        {/* In the meantime only for super admins */}
                        {getIsSuper() && (
                          <>
                            <Input id="file" name="file" type="file" onChange={onSelectFile} />
                            <br />
                            {preview && (
                              <img
                                style={{ maxHeight: '180px' }}
                                src={preview}
                                onError={() => setPreview('')}
                              />
                            )}
                          </>
                        )}
                        <div className="category form-category" style={{ textTransform: 'none' }}>
                          If you have any problems with your custom image / logo on the page, please
                          email partners@oath.vote
                        </div>
                      </FormGroup>
                    </CardBody>
                    <CardFooter className="text-left">
                      <Button
                        color="success"
                        onClick={handleSubmit}
                        disabled={pagesState.isLoading}
                      >
                        {editPageSelected ? 'Update Page' : 'Create Page'} &nbsp;{' '}
                        {pagesState.isLoading && <Loader type="spinner" width={16} height={16} />}
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </div>
          )}
          <Footer fluid />
        </div>
      </div>
    </>
  );
};

export default PagesDetail;
