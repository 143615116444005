import { Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';

const Footer = props => {
  return (
    <footer className={'footer' + (props.default ? ' footer-default' : '')}>
      <Container fluid={props.fluid ? true : false}>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a href="https://oath.vote" target="_blank">
                  OATH Site
                </a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright">
              &copy; {new Date().getFullYear()}, made with <i className="fa fa-heart heart" /> by
              Oath
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
