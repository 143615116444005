import { createActions } from 'reduxsauce';

export interface ITransactionState {
  data: ITransactionData;
  isLoading: boolean;
  error: string;
}
export interface ITransactionData {
  today: number;
  yesterday: number;
}

export interface TransactionTypes {
  TRANSACTION: 'TRANSACTION';
  TRANSACTION_START: 'TRANSACTION_START';
  TRANSACTION_TOTAL_TODAY_SUCCESS: 'TRANSACTION_TOTAL_TODAY_SUCCESS';
  TRANSACTION_TOTAL_YESTERDAY_SUCCESS: 'TRANSACTION_TOTAL_YESTERDAY_SUCCESS';
  TRANSACTION_TOTAL_ERROR: 'TRANSACTION_TOTAL_ERROR';
  TRANSACTION_CLEAN_UP: 'TRANSACTION_CLEAN_UP';
}

const { Types, Creators } = createActions<TransactionTypes>({
  transaction: ['data'],
  transactionStart: null,
  transactionTotalTodaySuccess: ['data'],
  transactionTotalYesterdaySuccess: ['data'],
  transactionTotalError: ['error'],
  transactionCleanUp: null,
});

export { Types };

export default Creators;
