const IconNames: { [Keys: string]: string } = {
  Bold: 'bold',
  Embed: 'embed',
  Italic: 'italic',
  ListNumbered: 'list-numbered',
  ListBulleted: 'list-bulleted',
  ParagraphLeft: 'paragraph-left',
  ParagraphRight: 'paragraph-right',
  ParagraphCenter: 'paragraph-center',
  Underline: 'underline',
};

export default IconNames;
