import { createActions } from 'reduxsauce';

export interface IDonationState {
  data: IDonationData;
  isLoading: boolean;
  error: string;
}
export interface IDonationData {
  today: number;
  yesterday: number;
  donationTrend: ITrendDonationData[];
}
export interface ITrendDonationData {
  date: number;
  amount: string;
}

export interface DonationTypes {
  DONATION: 'DONATION';
  DONATION_START: 'DONATION_START';
  DONATION_TREND_SUCCESS: 'DONATION_TREND_SUCCESS';
  DONATION_TREND_ERROR: 'DONATION_TREND_ERROR';
  DONATION_TOTAL_TODAY_SUCCESS: 'DONATION_TOTAL_TODAY_SUCCESS';
  DONATION_TOTAL_YESTERDAY_SUCCESS: 'DONATION_TOTAL_YESTERDAY_SUCCESS';
  DONATION_TOTAL_ERROR: 'DONATION_TOTAL_ERROR';
  DONATION_CLEAN_UP: 'DONATION_CLEAN_UP';
}

const { Types, Creators } = createActions<DonationTypes>({
  donation: ['data'],
  donationStart: null,
  donationTrendSuccess: ['data'],
  donationTrendError: ['error'],
  donationTotalTodaySuccess: ['data'],
  donationTotalYesterdaySuccess: ['data'],
  donationTotalError: ['error'],
  donationCleanUp: null,
});

export { Types };

export default Creators;
