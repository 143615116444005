import axios from 'axios';
import { Dispatch } from 'redux';
import USER_TYPES from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getUser =
  (email: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(USER_TYPES.userStart());
    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_USER(email)}`;

    try {
      const response = await axios.get(url, { headers });
      dispatch(USER_TYPES.userSuccess(response.data));
    } catch (error) {
      dispatch(USER_TYPES.userError(error));
    }
  };

export const setUserClientID =
  (clientID: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(USER_TYPES.userClientID(clientID));
  };

export const setUserClients =
  (clients: { id: string; name: string }[]) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(USER_TYPES.userClients(clients));
  };

export const setUserDates =
  (start: number, end: number, isUpdated: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(USER_TYPES.userDates({ start, end, isUpdated }));
  };
