import { jsx } from 'slate-hyperscript';
import { Editor, Element, Transforms } from 'slate';
import { LIST_TYPES, TEXT_ALIGN_TYPES } from './constants';
import { ElementNode, SlateNode, TextNode } from './types';

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => !Editor.isEditor(n) && Element.isElement(n) && n[blockType] === format,
    })
  );

  return !!match;
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<Element>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<Element>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes: any = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'B':
      nodeAttributes.bold = true;
      break;
    case 'U':
      nodeAttributes.underline = true;
      break;
    case 'I':
      nodeAttributes.italic = true;
      break;
    case 'CODE':
      nodeAttributes.code = true;
      break;
  }

  const children = Array.from(el.childNodes)
    .map(node => deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  let align = '';
  if (el.style.cssText && el.style.cssText.split(': ')[1]) {
    align = el.style.cssText.split(': ')[1].slice(0, -1);
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'P':
      if (!children[0].text) return jsx('element', { type: 'break' }, children);
      return jsx('element', { type: 'paragraph', align }, children);
    case 'OL':
      return jsx('element', { type: 'numbered-list', align }, children);
    case 'UL':
      return jsx('element', { type: 'bulleted-list', align }, children);
    case 'LI':
      return jsx('element', { type: 'list-item', align }, children);
    case 'A':
      return jsx('element', { type: 'link', url: el.getAttribute('href'), align }, children);
    default:
      return children;
  }
};

const isTextNode = (node: SlateNode): node is TextNode => {
  return (node as TextNode).text !== undefined;
};

const isElementNode = (node: SlateNode): node is ElementNode => {
  return (node as ElementNode).children !== undefined;
};

const wrapTextWithStyle = (text: string, node: TextNode): string => {
  let content = text;
  if (node.code) content = `<code>${content}</code>`;
  if (node.underline) content = `<u>${content}</u>`;
  if (node.italic) content = `<i>${content}</i>`;
  if (node.bold) content = `<b>${content}</b>`;
  return content;
};

const getTextContent = (node: TextNode): string => {
  const textContent = `<span data-slate-node="text"><span data-slate-leaf="true"><span data-slate-string="true">${node.text}</span></span></span>`;
  return wrapTextWithStyle(textContent, node);
};

export const convertToHTML = (nodes: SlateNode[]): string => {
  const convertNode = (node: SlateNode): string => {
    if (isElementNode(node)) {
      const alignStyle = node.align ? ` style="text-align: ${node.align};"` : '';
      switch (node.type) {
        case 'paragraph':
          if (isTextNode(node.children[0]) && !node.children[0].text) return `<br />`;
          return `<p data-slate-node="element"${alignStyle}>${node.children.map(convertNode).join('')}</p>`;
        case 'link':
          return `<a href="${node.url}">${node.children.map(convertNode).join('')}</a>`;
        case 'numbered-list':
          return `<ol data-slate-node="element"${alignStyle}>${node.children.map(convertNode).join('')}</ol>`;
        case 'bulleted-list':
          return `<ul data-slate-node="element"${alignStyle}>${node.children.map(convertNode).join('')}</ul>`;
        case 'list-item':
          return `<li data-slate-node="element"${alignStyle}>${node.children.map(convertNode).join('')}</li>`;
        default:
          return node.children.map(convertNode).join('');
      }
    } else if (isTextNode(node)) {
      return getTextContent(node);
    }
    return '';
  };

  return nodes.map(convertNode).join('');
};
