import { createActions } from 'reduxsauce';

export interface ITagsState {
  data: ITagsData;
  isLoading: boolean;
  error: string;
}
export interface ITagsData {
  tags: ITags[];
}
export interface ITags {
  client_id: string;
  desc: string;
  donation_amounts: number[];
  id: number;
  is_top_tag: boolean;
  query_str: string;
  value: string;
}

export interface TagsTypes {
  TAGS: 'TAGS';
  TAGS_START: 'TAGS_START';
  TAGS_SUCCESS: 'TAGS_SUCCESS';
  TAGS_ERROR: 'TAGS_ERROR';
  TAGS_CLEAN_UP: 'TAGS_CLEAN_UP';
}

const { Types, Creators } = createActions<TagsTypes>({
  tags: ['data'],
  tagsStart: null,
  tagsSuccess: ['data'],
  tagsError: ['error'],
  tagsCleanUp: null,
});

export { Types };

export default Creators;
