import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const recipientsStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const recipientsSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const recipientsError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const recipientsCleanUp = () => INITIAL_STATE;

export const recipientsFinishLoading = state => ({
  ...state,
  isLoading: false,
});

export const HANDLERS = {
  [Types.RECIPIENTS_START]: recipientsStart,
  [Types.RECIPIENTS_SUCCESS]: recipientsSuccess,
  [Types.RECIPIENTS_ERROR]: recipientsError,
  [Types.RECIPIENTS_CLEAN_UP]: recipientsCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
