import { FC, useState } from 'react';
import { ITableColumn } from 'constants/tables';
import { Collapse, Card, Button } from 'reactstrap';

interface Props {
  onSave: ((columns: ITableColumn[]) => void) | undefined;
  columns: ITableColumn[];
}

export const ColumnsToggle: FC<Props> = ({ onSave, columns }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState(columns);

  const toggle = () => setIsOpen(prevIsOpen => !prevIsOpen);

  const updateColumns = () => {
    onSave && onSave(options);
    toggle();
  };

  const updateOptions = (accessor: string) => {
    setOptions(prevOptions =>
      prevOptions.map(column =>
        column.accessor === accessor ? { ...column, visible: !column.visible } : column
      )
    );
  };

  return (
    <div>
      <span>Show / Hide Columns</span>
      <div className="IconContainer" onClick={toggle}>
        <i className={isOpen ? 'caret caretOpen' : 'caret'} />
      </div>
      <div className="CollapseContainer">
        <Collapse isOpen={isOpen}>
          <Card className="FilterOptionsCard">
            {options.map(column => (
              <div key={column.accessor} className="CheckboxContainer">
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      value={column.accessor}
                      checked={column.visible}
                      onChange={() => updateOptions(column.accessor)}
                    />
                    <span className="form-check-sign"></span>
                  </label>
                </div>
                <span key={column.accessor}>{column.Header}</span>
              </div>
            ))}
            <hr />
            <Button onClick={updateColumns} className="btn-primary">
              Save
            </Button>
          </Card>
        </Collapse>
      </div>
    </div>
  );
};
